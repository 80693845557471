import React from 'react';
import styled from 'styled-components';
import { Hero, SEO, Video, AXDemo, AXCases, RenderWhenReady } from 'components';
import { AxEngageLogo } from 'images/shared';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import PrevArrow from 'images/shared/PrevArrow';

const VirtualEventsPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        thumbnailImage: file(relativePath: { eq: "service-pages/studio.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
        weDesignImages: allFile(
          filter: { relativeDirectory: { eq: "service-pages/event-and-conferences/we-design" } }
          sort: { fields: name, order: [ASC] }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: AUTO
                  quality: 100
                )
              }
            }
          }
        }
        wePlanImages: allFile(
          filter: { relativeDirectory: { eq: "service-pages/event-and-conferences/we-plan" } }
          sort: { fields: name, order: [ASC] }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: AUTO
                  quality: 100
                )
              }
            }
          }
        }
        weProduceImages: allFile(
          filter: { relativeDirectory: { eq: "service-pages/event-and-conferences/we-produce" } }
          sort: { fields: name, order: [ASC] }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: AUTO
                  quality: 100
                )
              }
            }
          }
        }
        weAnalyzeImages: allFile(
          filter: { relativeDirectory: { eq: "service-pages/event-and-conferences/we-analyze" } }
          sort: { fields: name, order: [ASC] }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: AUTO
                  quality: 100
                )
              }
            }
          }
        }
      }
    `
  );

  const { weDesignImages, wePlanImages, weProduceImages, weAnalyzeImages } = data;

  return (
    <>
      <SEO
        pageSpecificTitle="Events & Conferences"
        pageSpecificDescription="Expertly designed, planned, produced and delivered… we provide in-person, online or hybrid events of all shapes and sizes."
      />
      <Hero>
        <HeroContent>
          <Directions>
            <StyledLink to="/#services">
              <BackButton>
                <StyledPrevArrow />
              </BackButton>
            </StyledLink>
            <StyledLink to="/services/healthcare-omnichannel-strategies">
              <NextButton>
                <StyledNextArrow />
              </NextButton>
            </StyledLink>
          </Directions>
          <h1>Events & Conferences</h1>
          <Text>
            <h2>
              Expertly designed, planned, produced and delivered… we provide in-person, online or
              hybrid events of all shapes and sizes.
            </h2>
            <p>
              From internal launches and townhalls, to in-hospital events and grand-scale
              conferences, no event is too big or small for our experienced event team.
            </p>
          </Text>
        </HeroContent>
      </Hero>
      <RenderWhenReady>
        <VideoSection style={{ paddingBottom: '1rem' }}>
          <p>CASE STUDY SPOTLIGHT - 2025 IPHA Annual Conference</p>
          <Video url="https://player.vimeo.com/video/1063251300?h=bbac974f57&amp;dnt=1" />
        </VideoSection>
      </RenderWhenReady>
      <Section>
        <Article>
          <Column>
            <h2>
              In-Person
              <br />
              Events & Conferences
            </h2>
            <h4>We Design</h4>
            <p>
              Our studio team of talented designers and creatives will help design each event&apos;s
              unique visual identity, expanding it across the full range of event assets, such as
              digital emailers and agendas, registrations pages, and social banners, to print
              collateral such as posters and lanyards.
            </p>
          </Column>
          <Column>
            <ThreeColumnImageContainer>
              <WideImage>
                <GatsbyImage
                  image={getImage(weDesignImages.edges[0].node.childImageSharp)}
                  alt="First Design Image"
                />
              </WideImage>
              <GroupedImages>
                {weDesignImages.edges.slice(1, 3).map((image, index) => (
                  <GatsbyImage
                    image={getImage(image.node.childImageSharp)}
                    alt={`${index} - Design Grouped Image`}
                  />
                ))}
              </GroupedImages>
            </ThreeColumnImageContainer>
          </Column>
        </Article>
      </Section>
      <Section>
        <Article reverse>
          <Column>
            <ThreeColumnImageContainer>
              <WideImage reverse>
                <GatsbyImage
                  image={getImage(wePlanImages.edges[0].node.childImageSharp)}
                  alt="First Plan Image"
                />
              </WideImage>
              <GroupedImages reverse>
                {wePlanImages.edges.slice(1, 3).map((image, index) => (
                  <GatsbyImage
                    image={getImage(image.node.childImageSharp)}
                    alt={`${index} - Plan Grouped Image`}
                  />
                ))}
              </GroupedImages>
            </ThreeColumnImageContainer>
          </Column>
          <Column center>
            <h4>We Plan</h4>
            <p>
              No matter the size or scale of your event, our event team experts will work closely
              with you from the start, putting in place a plan to bring your vision to life, for an
              event that captivates and engages your audience. From the pre-event digital marketing
              strategy, and bespoke registration web pages, to full event roll out and execution,
              we&apos;ll be with you every step along the way, ensuring every detail is expertly
              managed and each event is delivered to budget.
            </p>
          </Column>
        </Article>
      </Section>
      <Section>
        <Article>
          <Column center>
            <h4>We Produce</h4>
            <p>
              Our dedicated technical crew have over 30 years&apos; experience on site for each
              event. Their skills and technical expertise ensure flawless execution of your events,
              combining the latest technology with expert management. From sound and lighting to
              staging and AV, we deliver seamless event experiences with precision and
              professionalism.
            </p>
          </Column>
          <Column>
            <ThreeColumnImageContainer>
              <GroupedImages reverse invert>
                {weProduceImages.edges.slice(0, 2).map((image, index) => (
                  <GatsbyImage
                    image={getImage(image.node.childImageSharp)}
                    alt={`${index} - Produce Grouped Image`}
                  />
                ))}
              </GroupedImages>
              <WideImage reverse invert>
                <GatsbyImage
                  image={getImage(weProduceImages.edges[2].node.childImageSharp)}
                  alt="First Produce Image"
                />
              </WideImage>
            </ThreeColumnImageContainer>
          </Column>
        </Article>
      </Section>
      <Section>
        <Article reverse>
          <Column>
            <TwoColumnImageContainer>
              {weAnalyzeImages.edges.map((image, index) => (
                <WideImage>
                  <GatsbyImage
                    image={getImage(image.node.childImageSharp)}
                    alt={`${index} - Analyze Image`}
                  />
                </WideImage>
              ))}
            </TwoColumnImageContainer>
          </Column>
          <Column center>
            <h4>We Analyze</h4>
            <p>
              We always produce a post event report and full analytics. We can also provide a video
              of the full event or an edited version with keynote speakers along with a highlight
              reel for social sharing and post event engagement with your audience.
            </p>
          </Column>
        </Article>
      </Section>
      <Section>
        <Article uneven>
          <Column>
            <h2>
              Online &<br />
              Hybrid Events
            </h2>
          </Column>
          <Column>
            <Text>
              <AxEngageLogo />
              <p>
                <strong>AX ENGAGE</strong> is our custom built platform for connecting HCPs,
                industry and patient groups at all types of online or hybrid events. It can be fully
                customised to reflect your branding and event messaging. With additional features
                such as Abstract uploads and e-Ticketing functionality, <strong>AX ENGAGE</strong>{' '}
                covers all aspects of your event requirements.
              </p>
            </Text>
          </Column>
        </Article>
      </Section>
      <RenderWhenReady>
        <VideoSection style={{ paddingBottom: '3rem' }}>
          <Video url="https://player.vimeo.com/video/715130118?h=4bd3c499d3&dnt=1" />
        </VideoSection>
      </RenderWhenReady>
      <Section>
        <Article>
          <Column>
            <VideoWrapper>
              <GatsbyImage image={getImage(data.thumbnailImage)} alt="Video thumbnail" />
              <video
                muted
                autoPlay
                loop
                playsInline
                id="Virtual & Hybrid Healthcare Events"
                preload="auto"
                controls={false}>
                <source src="/studio.mp4" type="video/mp4" />
              </video>
            </VideoWrapper>
          </Column>
          <Column>
            <p>
              <strong>AX ENGAGE</strong> is ideal for one off events, or a content series, and
              offers unique platfrom features and functionalities such as social media integration
              and full compatibility with existing livestream platforms. It also allows for attendee
              engagement via Q&A functions, sponsor booths and integrated polling and surveys.
            </p>

            <p>
              From annual conferences and multi day symposia, to learning events, webinars,
              townhalls, and in-hospital events, <strong>AX ENGAGE</strong> delivers a seamless,
              professional event experience designed to engage and resonate with your audience.
            </p>
          </Column>
        </Article>
      </Section>
      <Section>
        <AXDemo />
      </Section>
      <Section>
        <h3 style={{ textTransform: 'uppercase' }}>Case Studies</h3>
        <p>
          Please find some of our recently produced events in our case study links below. All our
          projects are customised to the specific requirements of our clients and we will be
          delighted to share our experiences of best practice for your business event upon request.
        </p>
        <AXCases />
      </Section>
    </>
  );
};

const Directions = styled.div`
  align-items: center;
  display: flex;
  grid-column: span 2;
  justify-content: space-between;
  width: 100%;
`;

const BackButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.125rem;
  transition: 150ms ease-in-out;
  &:hover {
    background-color: #000;
  }
  &:not(:hover) {
    background-color: var(--ax-gold);
  }
  &:active {
    background-color: #c1beac;
  }
`;

const NextButton = styled(BackButton)`
  svg {
    transform: rotate(180deg);
  }
`;

const StyledPrevArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const StyledNextArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const StyledLink = styled(AnchorLink)`
  display: block;
  grid-column: 1;
  margin-top: -0.75em;
  margin-bottom: 1rem;
  @media (min-width: 48em) {
    grid-column: 1/3;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  h3 {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }
  h4 {
    color: var(--ax-gold);
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }
  p {
    line-height: 1.3em;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    max-height: 550px;
  }
  @media (min-width: 48rem) {
    font-size: 2rem;
    h4 {
      font-size: 2rem;
    }
  }
  @media (min-width: 67.5rem) {
    justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }
`;

const Article = styled.article`
  grid-column: 2/3;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
  grid-gap: 3rem;
  margin: 0 auto;
  h2 {
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
    line-height: 1.25;
  }
  @media (min-width: 48rem) {
    h2 {
      font-size: 2.75rem;
    }
  }
  @media (min-width: 67.5em) {
    display: grid;
    flex-direction: column;
    grid-gap: ${({ uneven }) => (uneven ? '2rem' : '5rem')};
    grid-template-columns: ${({ uneven }) => (uneven ? '7fr 6fr' : '1fr 1fr')};
  }
  @media screen and (min-width: 67.5rem) {
    h2 {
      font-size: 4rem;
    }
  }
  @media screen and (min-width: 82.5rem) {
    h2 {
      font-size: 3.5rem;
    }
  }
`;

const Section = styled.section`
  width: 100%;
  height: auto;
  max-width: 108.75rem;
  padding: 2rem 0;
  margin: 0 auto;
  > div {
    grid-column: 2/3;
  }
  h1 {
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
    line-height: 1.25;
  }
  > h2,
  h3 {
    color: var(--ax-gold);
    font-size: 1.75rem;
    grid-column: 2/3;
    margin-bottom: 3rem;
    text-align: center;
  }
  h3 {
    color: var(--ax-pink);
  }
  > p {
    font-size: 1.25rem;
    line-height: 1.3em;
    text-align: center;
    grid-column: 2/3;
    margin-bottom: 3rem;
  }
  @media (min-width: 48rem) {
    padding: 3rem;
    h1 {
      font-size: 2.75rem;
    }
    > h2,
    h3 {
      font-size: 2rem;
    }
    > p {
      margin-bottom: 5rem;
    }
  }
  @media screen and (min-width: 67.5rem) {
    h1 {
      font-size: 4rem;
    }
  }
  @media screen and (min-width: 82.5rem) {
    h1 {
      font-size: 4.5rem;
    }
  }
`;

const Text = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  margin-bottom: auto;
  padding-top: 1rem;
  margin-bottom: 1rem;
  h2 {
    font-size: 2rem;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 300;
  }
  svg {
    height: 1.5rem;
    align-self: flex-start;
    margin-bottom: 2rem;
  }
  @media (min-width: 48em) {
    svg {
      align-self: flex-start;
    }
  }
  @media (min-width: 67.5em) {
    svg {
      height: 2.5rem;
    }
  }
`;

const VideoSection = styled.div`
  width: 100%;
  height: auto;
  max-width: 108.75rem;
  padding: 3rem 1rem;
  margin: 0 auto;
  p {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }
  @media (min-width: 48rem) {
    padding: 3rem;
    p {
      margin-left: calc(6.25% - 3rem);
    }
  }
  @media (min-width: 90rem) {
    p {
      margin-left: calc(9% - 3rem);
    }
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    grid-column: span 2;
  }
  @media screen and (min-width: 48rem) {
    h1 {
      font-size: 2.75rem;
      grid-column: span 1;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    max-width: 1180px;
    margin: 3rem auto 3rem;
  }
  @media screen and (min-width: 67.5rem) {
    h1 {
      font-size: 4rem;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
  @media screen and (min-width: 82.5rem) {
    h1 {
      font-size: 4.5rem;
    }
  }
`;

const ThreeColumnImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;
  position: relative;
`;

const TwoColumnImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 1rem;
  width: 100%;
  height: 100%;
  > div:first-child {
    grid-column: 1/8;
    grid-row: 1/5;
  }
  > div:last-child {
    grid-column: 5/11;
    grid-row: 3/6;
  }
`;

const WideImage = styled.div`
  width: 100%;
  height: auto;
  max-width: ${({ invert }) => (invert ? '500px' : '478px')};
  margin-left: ${({ reverse, invert }) => (invert ? '-0.5rem' : reverse ? '0' : 'auto')};
  margin-top: ${({ invert }) => (invert ? '-3rem' : '0')};
  margin-right: ${({ reverse }) => (reverse ? 'auto' : '0')};
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  @media (min-width: 48rem) {
    max-width: ${({ invert }) => (invert ? '500px' : '478px')};
    margin-left: ${({ reverse, invert }) => (invert ? '-3rem' : reverse ? '0' : 'auto')};
  }
`;

const GroupedImages = styled.div`
  display: grid;
  grid-template-columns: ${({ invert }) => (invert ? '3fr 4fr' : '1fr 1fr')};
  grid-gap: 1.125rem;
  max-height: ${({ invert }) => (invert ? '360px' : '100%')};
  max-width: ${({ invert }) => (invert ? '478px' : '100%')};
  margin-top: ${({ invert }) => (invert ? '0' : '-3rem')};
  margin-right: ${({ reverse }) => (reverse ? '0' : '1.125rem')};
  margin-left: ${({ reverse }) => (reverse ? '1.125rem' : '0')};
`;

export default VirtualEventsPage;
